@import "../../styles/colors";

#red-highlight {
    color: $highlight-red;
}

#not-found-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;

    h2 {
        margin: 0;
    }

    p {
        width: 80%;
        text-align: center;
    }
  }