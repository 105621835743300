nav {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    background-color: #282c34;
    color: white;
    z-index: 100;
  
    a, button {
      float: right;
      margin: 10px;
      padding: 3px;
      border: solid 1px white;
      border-radius: 2px;
      text-decoration: none;
    }
  }

  #home-link {
    float: left;
  }

  .fade-in {
    animation: fadeIn 1s forwards;
  }

  .fade-out {
    animation: fadeOut 0.5s forwards;
  }

  .nav-border {
    border-bottom: solid 1px white;
  }

  .nav-animate-border {
    animation: borderAnimate 1s forwards;
  }

  .no-nav-border {
    animation: noBorderAnimate 0.5s forwards;
  }

  .no-opacity {
    opacity: 0;
  }