@import "../../styles/colors.scss";

#about-page {
    #profile-photo {
        width: 150px;
        border-radius: 25px;
        display: block;
        float: left;
        margin-right: 25px;
    }

    .p-section-title {
        color: $highlight-red;
    }

    p {
        line-height: 30px;
        font-size: large;
        text-align: justify;
        padding: 0px 25px 0px 25px;
    }

    a {
        color: white;
        font-weight: bolder;
    }
}