@import "colors.scss";

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

@keyframes bounce {
    0% { transform:translateY(0%); }
    10% { transform:translateY(-10%); }
    20% { transform:translateY(0); }
    30% { transform:translateY(-3%); }
    40% { transform:translateY(0); }
}

@keyframes borderAnimate {
    0% {
        border-bottom: solid 1px $background-color;
    }
 
    100% {
        border-bottom: solid 1px white;
    }
}

@keyframes noBorderAnimate {
    0% {
        border-bottom: solid 1px white;
    }
 
    100% {
        border-bottom: solid 1px $background-color;
    }
}