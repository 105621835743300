@import "../../styles/colors.scss";
@import "../../styles/variables.scss";

$bannerFadeInLength: 3s;
$bannerFontSize: 46px;

#page-content {
    background-color: $background-color;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  #front-page-banner-one {
    font-size: 30px;
    animation: fadeIn $bannerFadeInLength forwards;
    display: inline-block;
  }
  
  #front-page-banner-two {
    font-size: 30px;
    animation: fadeIn $bannerFadeInLength forwards;
    animation-delay: 1s;
    opacity: 0;
    display: inline-block;
  }

  @media only screen and (min-width: $small) {
    #front-page-banner-one {
      font-size: $bannerFontSize;
    }
    
    #front-page-banner-two {
      font-size: $bannerFontSize;
    }
  }
  
  #social-icons-container {
    margin-top: 20px;
  }
  
  .social-logo {
    width: 60px;
    height: 60px;
    margin: 0px 20px;
  }

  .social-logo:hover {
    animation: bounce 2s infinite;
  }
  
  #exercism-logo {
    border-radius: 50px;
    border: solid 1px darkslategrey;
  }

  #stackoverflow-logo {
    border-radius: 50px;
    background-color: white;
  }
  
  @mixin btn-border-drawing($color: #ccc, $hover: black, $width: 2px, $vertical: top, $horizontal: left, $duration: 0.25s) {
    box-shadow: inset 0 0 0 $width $color;
    color: $color;
    transition: color $duration $duration/3;
    position: relative;
    
    &::before,
    &::after {
      border: 0 solid transparent;
      box-sizing: border-box;
      content: '';
      pointer-events: none;
      position: absolute;
      width: 0; height: 0;
      
      #{$vertical}: 0; 
      #{$horizontal}: 0;
    }
  
    &::before {
      $h-side: if($horizontal == 'left', 'right', 'left');
      
      border-#{$vertical}-width: $width;
      border-#{$h-side}-width: $width;
    }
    
    &::after {
      $v-side: if($vertical == 'top', 'bottom', 'top');
      
      border-#{$v-side}-width: $width;
      border-#{$horizontal}-width: $width;
    }
    
    &:hover {
      color: $hover;
      
      &::before,
      &::after {
        border-color: $hover;
        transition: border-color 0s, width $duration, height $duration;
        width: 100%;
        height: 100%;
      }
      
      &::before { transition-delay: 0s, 0s, $duration; }
      
      &::after { transition-delay: 0s, $duration, 0s; }
    }
  }
  
  .draw-border {
    @include btn-border-drawing(white, $highlight-red, 2px, bottom, right);
  }
  
  //=== Button styling, semi-ignore
  .btn {
    background: none;
    border: none;
    cursor: pointer;
    line-height: 1.5;
    font: 700 0.9rem 'Roboto Slab', sans-serif;
    padding: 1em 2em;
    letter-spacing: 0.05rem;
  }